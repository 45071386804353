import { getUserGuidanceStep } from "@!/api/Account/setting"
import { getWhiteName } from "@!/api/User"
import { dayjs } from "@pacvue/element-plus"
import useAdvertisingStore from "./views/Advertising/advertising-store"
import { useUserStore, useCommonStore } from "@/store"

if (localStorage.getItem("locale") === "ZH") {
  dayjs.locale("zh-cn")
  dayjs.updateLocale("zh-cn", {
    weekStart: 0, // OPTIONAL, set the start of a week. If the value is 1, Monday will be the start of week instead of Sunday。
    yearStart: 4 // OPTIONAL, the week that contains Jan 4th is the first week of the year.
  })
} else {
  dayjs.locale("en")
  dayjs.updateLocale("en", {
    weekStart: 0, // OPTIONAL, set the start of a week. If the value is 1, Monday will be the start of week instead of Sunday。
    yearStart: 4 // OPTIONAL, the week that contains Jan 4th is the first week of the year.
  })
}

const excludeRoute = ["/oauth2/redirect", "/Guide", "/Binding", "/Notification", "/Account/MyAccount", "/login", "/login/loginwithsso", "loginoauth/redirect", "/oauth2/vendorredirect"]
const needDisplayWhiteNameRoute = [
  "/Campaign/VideoManagement",
  "/Campaign/Library",
  "/Campaign/Campaign",
  "/Campaign/Explorer",
  "/Campaign/AdGroup",
  "/Campaign/CreateDisplayCampaign",
  "/Campaign/CreateSPCampaign",
  "/Advertising/CreateAdGroup",
  "/Advertising/Campaign"
]
export default {
  loginInterceptors: function (router) {
    router.push(window.HomePage || "/Home")
  },
  routerInterceptors: [
    (to) => ({
      syncSeq: 0,
      async then(onFulfill, onReject) {
        try {
          if (!to.query.shareId) {
            const advertisingStore = useAdvertisingStore()
            if (!advertisingStore.supportInfo) {
              await advertisingStore.GetCheckSupportInfo()
            }
          }
          onFulfill(true)
        } catch (err) {
          onReject(err)
        }
      }
    }),
    (to) => ({
      syncSeq: 0,
      async then(onFulfill, onReject) {
        try {
          if (!to.query.shareId) {
            const commonStore = useCommonStore()
            if (commonStore.curStore.profileList.length === 0) {
              await commonStore.getProfileList([])
            }
          }
          onFulfill(true)
        } catch (err) {
          onReject(err)
        }
      }
    }),
    // 偏好相关 (包含平台特有)
    (to) => ({
      syncSeq: 0,
      async then(onFulfill, onReject) {
        try {
          if (!to.query.shareId) {
            const userStore = useUserStore()
            let guidanceRes = userStore.userGuidanceInfo
            if (!guidanceRes) {
              guidanceRes = await getUserGuidanceStep()
              userStore.setUserGuidanceInfo(guidanceRes)
            }
          }
          onFulfill(true)
        } catch (err) {
          onReject(err)
        }
      }
    }),
    // profile 权限相关
    (to, from, next) => ({
      syncSeq: 0,
      async then(onFulfill, onReject) {
        try {
          if (!to.query.shareId) {
            const commonStore = useCommonStore()
            if (!excludeRoute.includes(to.path) && to.path !== "/Guidance") {
              let profiles = JSON.parse(sessionStorage.getItem("userProfiles"))
              if (!profiles || profiles.length === 0) {
                profiles = await commonStore.getProfilesPlatform().catch((ex) => sessionStorage.clear())
                if (profiles === undefined) {
                  onReject("Profiles request error!")
                }
              }
            }
          }
          onFulfill(true)
        } catch (err) {
          onReject(err)
        }
      }
    }),
    // PW-17498 特殊白名单
    (to, from, next) => ({
      syncSeq: 0,
      async then(onFulfill, onReject) {
        try {
          if (!to.query.shareId && needDisplayWhiteNameRoute.includes(to.path)) {
            const userStore = useUserStore()
            const userId = userStore.user?.userId
            // inNextGuardLoops: 用于阻止同一次路由过程中的重复调用
            if (!window.inNextGuardLoops) {
              const whiteName = await getWhiteName()
              localStorage.setItem(`${userId}_SettingWhiteName`, whiteName?.campaignWhiteProfileIds?.length > 0 ? JSON.stringify(whiteName.campaignWhiteProfileIds) : JSON.stringify([]))
              localStorage.setItem(`${userId}_SettingTROASWhiteName`, whiteName?.targetROASWhiteProfileIds?.length > 0 ? JSON.stringify(whiteName.targetROASWhiteProfileIds) : JSON.stringify([]))
              localStorage.setItem(
                `${userId}_SettingSelectionTypeWhiteName`,
                whiteName?.selectionTypeWhiteProfileIds?.length > 0 ? JSON.stringify(whiteName.selectionTypeWhiteProfileIds) : JSON.stringify([])
              )
              localStorage.setItem(`${userId}_SettingCreativeWhiteName`, whiteName?.creativeWhiteProfileIds?.length > 0 ? JSON.stringify(whiteName.creativeWhiteProfileIds) : JSON.stringify([]))
            }
          }
          onFulfill(true)
        } catch (err) {
          onReject(err)
        }
      }
    }),
    // 最终处理、路由拦截
    (to, from, next) => ({
      syncSeq: 1,
      async then(onFulfill, onReject) {
        try {
          if (!to.query.shareId) {
            const userStore = useUserStore()
            const userId = userStore.user?.userId
            const userSettings = userStore.userSettings || {}
            // 处理菜单
            let guidanceRes = userStore.userGuidanceInfo
            // 是否完成第一步偏好设置 (平台无关)
            if (userSettings?.dateFormat) {
              localStorage.setItem(`${userId}_SettingStep`, 1)
              // 进一步判断进行到第二步还是第三步引导设置 (平台独立)
              const settingStep = guidanceRes?.step ?? 1
              localStorage.setItem(`${userId}_SettingStep`, settingStep)
              localStorage.setItem(`${userId}_SettingState`, settingStep == 3 ? 1 : 0)
              localStorage.setItem(`${userId}_SettingStore`, guidanceRes?.includeStore)
              localStorage.setItem(`${userId}_SettingDisplay`, guidanceRes?.includeDisplay)
            } else {
              localStorage.setItem(`${userId}_SettingState`, 0)
              localStorage.setItem(`${userId}_SettingStep`, 0)
              localStorage.setItem(`${userId}_SettingStore`, "")
              localStorage.setItem(`${userId}_SettingDisplay`, "")
            }
            let locale = localStorage.getItem("locale")
            if (!locale || locale !== userSettings.language) {
              window.prefLangDiffered = true
              localStorage.setItem("locale", userSettings.language)
              // if (i18n.mode === "legacy") {
              //   i18n.global.locale = userSettings.language
              // } else {
              //   i18n.global.locale.value = userSettings.language
              // }
            }
            var elementLocale = "en"
            if (localStorage.getItem("locale") === "ZH") {
              elementLocale = "zh-cn"
            } else {
              elementLocale = "en"
            }
            dayjs.updateLocale(elementLocale, {
              weekStart: 0, // OPTIONAL, set the start of a week. If the value is 1, Monday will be the start of week instead of Sunday。
              yearStart: 4 // OPTIONAL, the week that contains Jan 4th is the first week of the year.
            })
            userStore.SET_USERSETTINGS({ ...(userSettings || {}), defaultCurrency: "US" })
            const guidanceSetIncomplete = localStorage.getItem(`${userId}_SettingState`) != "1"
            const profiles = JSON.parse(sessionStorage.getItem("userProfiles"))
            if (!guidanceSetIncomplete && to.path === "/Guidance") {
              // 如果引导设置已全部完成, 且还要跳转至引导页, 则重定向至 MyAccount 页
              next("/Account/MyAccount")
            }
            if (!excludeRoute.includes(to.path) && to.path !== "/Guidance") {
              let sovUrl = [
                "/Analysis/Competitive",
                "/Analysis/SOVDashboard",
                "/Analysis/SOVBrand",
                "/Analysis/SOVKeyword",
                "/Analysis/SOVAsin",
                "/Analysis/SOVInsight",
                "/Analysis/ManageKeywordTag",
                "/Analysis/CompetitorAsinAnalysis",
                "/Analysis/BrandsMapping",
                "/Analysis/KeywordDetail"
              ]
              let ignoreSov = !(sovUrl.includes(to.path) && userStore.user.clientId == 3323)
              if (profiles?.length === 0 && ignoreSov) {
                localStorage.setItem(`${userId}_NoneAuthorized`, true)
                localStorage.setItem(`${userId}_BindProfile`, false)
                next("/Guide")
              } else {
                localStorage.setItem(`${userId}_NoneAuthorized`, false)
                localStorage.setItem(`${userId}_BindProfile`, true)
                // 是否完成引导设置
                if (guidanceSetIncomplete) {
                  next("/Guide")
                }
              }
            }
          }
          onFulfill(true)
        } catch (err) {
          onReject(err)
        }
      }
    })
  ]
}
