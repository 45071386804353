import { app, InitApp } from "@pacvue/frame"
import permission from "@pacvue/frame/permission.js"
import router from "./router"
import routerFunction from "@pacvue/frame/routerFunction"
import budgetRouter from "@BudgetManager/budgetManagerRouter.js"
import eventRouter from "@event/eventRouter.js"
import Message from "@Message/message.js"
import userManageRouter from "@permission/permission.js"
import Dayparting from "@dayparting/dayparting.js"
import bidexplorer from "@bidexplorerv3/bidexplorer.js"
import ASINCenterAI from "@AsinCenterAIv3/ASINCenterAI.js"
import campaignAIRouter from "@campaignAI/campaignAIRouter.js"
import ruleRouter from "@rule/router.js"
import billingTagRouter from "@billingTag/billingtag.js"
import Login from "@pacvue/frame/login"
import essentialPlugin from "@pacvue/frame/essentialPlugin"
import walmartPlugin from "./walmartPlugin"
import { useAppStore } from "@/store"
import { analyticsPage, useAnalytics } from "@pacvue/frame/const"
import budgetschedule from "@budgetschedulev3/budgetschedule.js"
import { initDataDog } from "@pacvue/frame/datadog"
import Highcharts from "highcharts/highstock"
import HighchartsMore from "highcharts/highcharts-more"
import Exporting from "highcharts/modules/exporting"
import Annotations from "highcharts/modules/annotations"
import { FullStory, init } from "@fullstory/browser"
// 离线导出功能
import offlineExporting from "highcharts/modules/offline-exporting"
import { isReminderUserToBillingTag } from "@billingTag/reminder.js"

const { VITE_APP_NODE_ENV, VITE_APP_APIENV } = import.meta.env
HighchartsMore(Highcharts)
Exporting(Highcharts)
offlineExporting(Highcharts)
Annotations(Highcharts)

// 未来需要删除
// var platform = localStorage.getItem("productline")
// if (!platform || platform != "amazon" || platform != "walmart") {
//   localStorage.setItem("productline", "walmart")
// }
if (window.location.host.indexOf("samsclub") !== -1) {
  localStorage.setItem("productline", "samsclub")
} else {
  localStorage.setItem("productline", "walmart")
}
if (localStorage.getItem("locale") && !["ZH", "EN"].includes(localStorage.getItem("locale"))) {
  localStorage.setItem("locale", "EN")
  // if (i18n.mode === "legacy") {
  //   i18n.global.locale = "EN"
  // } else {
  //   i18n.global.locale.value = "EN"
  // }
}

InitApp.extend(budgetRouter)
InitApp.extend(Message)
InitApp.extend(eventRouter)
InitApp.extend(campaignAIRouter)
InitApp.extend(ASINCenterAI)
InitApp.extend(bidexplorer)
InitApp.extend(ruleRouter)
InitApp.extend(userManageRouter)
InitApp.extend(Dayparting)
InitApp.extend(Login)
InitApp.extend(essentialPlugin)
InitApp.extend(walmartPlugin)
InitApp.extend(budgetschedule)
InitApp.extend(billingTagRouter)
InitApp.integrateRouterInterceptors()

permission(router, routerFunction)

InitApp.init(router, "#vm")
useAnalytics()
if (VITE_APP_APIENV == "us") {
  init({ orgId: "o-21PMZA-na1" })
}
app.config.globalProperties.$eventHub.on("routerBeforeHook", function (config) {
  if (config.from.fullPath === "/login") {
    let uidNow = localStorage.getItem("uid")
    window.localStorage.removeItem(uidNow + "_budgetAlert_SubTag")
    window.localStorage.removeItem(uidNow + "_budgetAlert_Calendar")
    window.localStorage.removeItem(uidNow + "_budgetAlert_Profile")
    window.localStorage.removeItem("thisTimePreferenceShow")
  }
})
const appStore = useAppStore()
appStore.SET_ISREALTIMEBYPST(true)
appStore.SET_DATEPICKEROPTIONS({
  hasDayBeforeYesterday: true
})
app.config.globalProperties.$eventHub.on("routerAfterEachHook", function ({ to, from, next }) {
  analyticsPage({ to, from, next })
  isReminderUserToBillingTag(to, from, next)
  if (VITE_APP_APIENV == "us") {
    setTimeout(() => {
      FullStory("setProperties", {
        type: "page",
        properties: {
          env: `${VITE_APP_NODE_ENV}-${VITE_APP_APIENV}`,
          platform: "Walmart",
          productline: "Pacvue"
        }
      })
    }, 500)
  }
})

localStorage.setItem("latestLogin", localStorage.getItem("productline"))
// 域名配置及后缀
const hostnameConfig = {
  "walmart.pacvue.com": "us",
  "walmart.pacvue.cn": "cn",
  "product.pacvue.com": "us",
  "product.pacvue.cn": "cn",
  "groupm.pacvue.com": "us"
}

// 仅在正式域名下datadog生效
const hostname = window.location.hostname
if (hostname in hostnameConfig && localStorage.getItem("productline") === "walmart") {
  initDataDog(hostnameConfig[hostname])
}

app.config.globalProperties.$eventHub.on("getUserInfo", (data) => {
  if (VITE_APP_APIENV == "us") {
    FullStory("setIdentity", {
      uid: data?.userId,
      properties: {
        displayName: data?.userName,
        email: data?.mail,
        clientId: data?.clientId,
        byPacvuer: data?.byPacvuer || "Real User"
      }
    })
  }
})
