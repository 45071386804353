import request from "@pacvue/frame/request"

const { VITE_APP_WALMART_BASE_URL, VITE_APP_MENU_API, VITE_APP_USERURL, VITE_APP_META } = import.meta.env

export function getProfiles() {
  return request({
    url: `${VITE_APP_WALMART_BASE_URL}User/profiles`,
    method: "GET"
  })
}

export function getUserProfiles(data, isExport = false) {
  var obj = {
    url: `${VITE_APP_WALMART_BASE_URL}User/userProfiles?isExport=${isExport}`,
    method: "post",
    data
  }
  if (isExport) {
    obj.responseType = "blob"
  }
  return request(obj)
}

export function getUserProfilesMeta(data) {
  return request({
    url: `${VITE_APP_META}apply/getProfilesByAll`,
    method: "post",
    data
  })
}

export function getManagementLogs(data) {
  return request({
    url: `${VITE_APP_WALMART_BASE_URL}User/profile/operation/logs`,
    method: "POST",
    data: data
  })
}

export function editUserProfileAlias(data) {
  return request({
    url: `${VITE_APP_WALMART_BASE_URL}User/profiles/aliasName`,
    method: "PATCH",
    data: data
  })
}

export function getUserList(query) {
  return request({
    url: `${VITE_APP_WALMART_BASE_URL}User/subUsers`,
    method: "GET",
    params: query
  })
}

export function getAdminEmail() {
  return request({
    url: `${VITE_APP_USERURL}User/getAdminEmail`,
    method: "GET"
  })
}

export function getUserInfo(userId) {
  return request({
    url: `${VITE_APP_MENU_API}userManager/getUserInfo/${userId}`,
    method: "GET"
  })
}

export function unbindUserProfile(data) {
  return request({
    url: `${VITE_APP_WALMART_BASE_URL}User/UnBind`,
    method: "PUT",
    data: data
  })
}

export function postManageProfile(data) {
  return request({
    url: `${VITE_APP_WALMART_BASE_URL}User/profile/manage`,
    method: "PUT",
    data: data
  })
}

export function postCloseAutomation(profileId) {
  return request({
    url: `${VITE_APP_WALMART_BASE_URL}User/unManageProfile/automation/${profileId}`,
    method: "GET"
  })
}

export function postCloseAutomationUnbind(profileId) {
  return request({
    url: `${VITE_APP_WALMART_BASE_URL}User/unbindProfile/automation/${profileId}`,
    method: "GET"
  })
}
export function ApiAccessTypeChangeLog(data) {
  return request({
    url: `${VITE_APP_WALMART_BASE_URL}Profile/ApiAccessTypeChangeLog`,
    method: "POST",
    data
  })
}
