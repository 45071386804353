import { getProfile } from "@/api/common"
import { getUserProfilesMeta } from "@!/api/Account/index"
import * as storeModule from "../index"
import { useCommonStore, useAppStore, useUserStore } from "../index"
import { strCapitalize } from "../util.js"
import { getAllProfiles } from "@/api/Advertising/profile"

export default {
  TEST_FN(playload) {
    var commonStore = useCommonStore()
    // eslint-disable-next-line import/namespace
    storeModule[`use${strCapitalize(commonStore.platform)}Store`]().TEST_FN(playload)
    //commit(`${state.platform}/TEST_FN`, playload, { root: true })
  },
  getProfilesPlatform() {
    return new Promise((resolve, reject) => {
      const curStore = this.curStore
      const userStore = useUserStore()
      getUserProfilesMeta({ userId: userStore.user?.userId })
        .then((res) => {
          res = res?.map((item) => ({ ...item, profileId: item.id, profileName: item.name }))?.filter((item) => !!item.isSelected) ?? []
          curStore.setProfileListAccount(res)
          sessionStorage.setItem("userProfiles", JSON.stringify(res))
          resolve(res)
        })
        .catch((err) => reject(`login failed during profiles request : ${err}`))
    })
  },
  getProfileList(playload, isRefresh) {
    var appStore = useAppStore()
    var curStore = this.curStore
    return new Promise((resolve) => {
      var cacheData = curStore.profileList || []
      if (cacheData.length && !isRefresh) {
        resolve(cacheData)
        return
      }

      if (!playload || playload.length == 0) {
        playload = appStore.chooseAccounts
      }
      getProfile(playload).then((data) => {
        curStore.setProfileList(data)
        resolve(data)
      })
    })
  },
  getAllProfileList(playload, isRefresh) {
    var appStore = useAppStore()
    var curStore = this.curStore
    return new Promise((resolve) => {
      var cacheData = curStore.allProfileList || []
      if (cacheData.length && !isRefresh) {
        resolve(cacheData)
        return
      }
      // console.log(appStore.chooseAccounts, ">>>>>>")
      if (!playload || playload.length == 0) {
        // console.log(appStore.chooseAccounts, ">>>>>>")
        playload = appStore.chooseAccounts
      }
      getAllProfiles(playload).then((data) => {
        curStore.setAllProfileList(data)
        resolve(data)
      })
    })
  },
  SET_PLATFORM(value) {
    this.platform = value || "amazon"
    localStorage.setItem("productline", value)
  },
  // 设置排序面板状态
  SET_SORT_PANEL_STATUS(value) {
    this.sortPanelStatus = value
  },
  // 设置排序面板数据
  SET_DASHBOARD_ARRAY(value) {
    this.dashboardArray = value
  },
  // 设置其他排序面板数据
  SET_OTHER_DASHBOARD_ARRAY(value) {
    this.otherDashboardArray = value
  },
  //common类型勾选Ids
  SET_COMMON_DASHBOARD_IDS(value) {
    this.commonDashboardIds = value
  },
  // 设置profileIds以及selectList
  SET_PROFILE_IDS(value) {
    this.profileIds = value
  },
  SET_SHARE_PROFILE_IDS(value) {
    this.shareProfileIds = value
  },
  // 设置profileIds以及selectList
  SET_CAMPAIGN_TYPES(value) {
    this.campaignTypes = value
    console.log(value)
  },
  SET_SHARE_CAMPAIGN_TYPES(value) {
    this.shareCampaignTypes = value
  },
  //设置SovBrand
  SET_SHARE_SELECT_SOV_BRANDS(value) {
    this.shareSelectSovBrands = value
  },
  // 设置analyticsProfileId
  SET_ANA_PROFILE_ID(value) {
    this.analyticsProfileId = value
  },
  // 设置analyticsAdvertiser
  SET_ANA_ADVER(value) {
    this.advertiserIds = value
  },
  // 设置analyticsAttribution
  SET_ANA_ATTR(value) {
    this.attrAdvertiserIds = value
  },
  // 设置profile-selectList
  SET_PROFILE_LIST(value) {
    this.profileList = value
  },
  // 设置ShareCampaignTags
  SET_SHARE_CAMPAIGN_TAGS(value) {
    this.shareCampaignTags = value
  },
  // 设置CampaignTags
  SET_CAMPAIGN_TAGS(value) {
    this.campaignTags = value
  },
  // 设置NoCampaignTags
  SET_NOCAMPAIGN_TAGS(value) {
    this.noTagCampaigns = value
  },
  // 设置NoCampaignTags集合
  SET_NOCAMPAIGN_TAGSList(value) {
    this.noTagCampaignLists = value
  },
  // 设置CampaignTagsAll
  SET_CAMPAIGN_TAGS_ALL(value) {
    this.campaignTagsAll = value
  },
  // 设置Porfolios
  SET_PORFOLIOS(value) {
    this.portfolio = value
  },
  // 设置Share偏好
  SET_SHARE_PREFERENCE(value) {
    this.sharePreference = value
  },
  //设置per 三个指标
  SET_CONTENT_PER(value) {
    this.showContentPer = value
  },
  //设置Eff 三个指标
  SET_CONTENT_EFF(value) {
    this.showContentEff = value
  },
  SET_IS3P(value) {
    this.is3p = value
  },
  setSelectedProfiles(playload) {
    this.curStore.setSelectedProfiles(playload)
  },
  setSelectedCampaignTypes(playload) {
    this.curStore.setSelectedCampaignTypes(playload)
  },
  setSelectedCampaignTags(playload) {
    this.curStore.setSelectedCampaignTags(playload)
  },
  setSelectedPorfolios(playload) {
    this.curStore.setSelectedPorfolios(playload)
  },
  setSelectedCampaigns(playload) {
    this.curStore.setSelectedCampaigns(playload)
  },
  setSelectedAdgroups(playload) {
    this.curStore.setSelectedAdgroups(playload)
  },
  setUserPersist() {
    this.curStore.setUserPersist()
  },
  logout() {
    // console.log(storeModule, "1")
    for (var i in storeModule) {
      // eslint-disable-next-line import/namespace
      storeModule[i]().$reset()
    }
  },
  //home widget 放大功能
  SET_HOME_PRODUCTS(value) {
    this.homeProducts = value
  },
  SET_HOME_BUDGET(value) {
    this.homeBudget = value
  },
  SET_HOME_PRESCRIPTIVEINSIGHTBYTAG(value) {
    this.homePrescriptiveInsightByTag = value
  },
  SET_HOME_BRANDSOV(value) {
    this.homeBrandSOV = value
  },
  SET_HOME_BRANDSOV_CATEGORYVALUE(value) {
    this.homeBrandSOVCategoryValue = value
  },
  SET_HOME_BRANDSOV_SORT(value) {
    this.homeBrandSOVSort = value
  },
  SET_HOME_CAMPAIGNTAGPER(value) {
    this.homeCampaignTagPer = value
  },
  SET_HOME_PLACEMENTPERF(value) {
    this.homePlacementPerf = value
  },
  SET_HOME_TOP20(value) {
    this.homeTop20 = value
  },
  SET_HOME_CAMPAIGNGOAL(value) {
    this.homeCampaignGoal = value
  },
  SET_HOME_NONBID(value) {
    this.homeNonBid = value
  },
  SET_HOME_KEYWORDS(value) {
    this.homeKeywords = value
  },
  SET_HOME_KEYWORDSCOL(value) {
    this.homeKeywordsColumn = value
  },
  SET_HOME_KEYWORDSTAGPERF(value) {
    this.homeKeywordsTagPerf = value
  },
  SET_HOME_ACTUALSPEND(value) {
    this.homeActualSpend = value
  },
  SET_HOME_PAGETYPEPERFORMANCE(value) {
    this.homePageTypePerformance = value
  },
  SET_HOME_PLATFORMPERFORMANCE(value) {
    this.homePlatformPerformance = value
  },
  SET_HOME_CAMPAIGNiNSUFFICIENT(value) {
    this.homeCampaignInsufficient = value
  },
  SET_COMMON_FILTERS(playload) {
    this.curStore.setCommonFilters(playload)
  },
  SET_EXPLORER_COMMON_FILTERS(playload) {
    this.curStore.setExplorerCommonFilters(playload)
  },
  SET_HOME_TOPWIDGETSDATA(value) {
    this.homeTopWidgetsData = value
  },
  SET_CHANNEL_TYPE(value) {
    this.channelType = value
  }
}
